<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path opacity="0.97" d="M4 2V21L12 15.0619L20 21V2H4Z" :fill="fillColor" />
  </svg>
</template>

<script>
export default {
  props: ["fill"],

  data() {
    return {
      fillColor: this.fill ? this.fill : "#343C44",
    };
  },
};
</script>