<template>
  <nav aria-label="Page navigation">
    <ul class="pagination">
      <li :class="'page-item' + (value == 1 ? ' disabled' : '')">
        <button
          class="page-link"
          aria-label="Previous"
          @click="choosePage(value - 1)"
        >
          <span aria-hidden="true">&laquo;</span>
        </button>
      </li>
      <li
        :class="'page-item' + (value == page ? ' disabled' : '')"
        v-for="page in pages"
        :key="page"
      >
        <button class="page-link" @click="choosePage(page)">
          {{ page }}
        </button>
      </li>
      <li :class="'page-item' + (value == last_page ? ' disabled' : '')">
        <button
          class="page-link"
          aria-label="Next"
          @click="choosePage(value + 1)"
        >
          <span aria-hidden="true">&raquo;</span>
        </button>
      </li>
    </ul>
  </nav>
</template>

<style scoped>
</style>

<script>
export default {
  props: {
    value: null,
    last_page: null,
  },

  computed: {
    pages() {
      let start_page = 1;
      let end_page = this.last_page;
      let pages = [];

      if (this.value == this.last_page) {
        start_page = this.value - 2;
        if (start_page < 1) {
          start_page = 1;
        }
      } else if (this.value > 1) {
        start_page = this.value - 1;
      }

      if (this.last_page > start_page + 2) {
        end_page = start_page + 2;
      }

      for (let i = start_page; i <= end_page; i++) {
        pages.push(i);
      }

      return pages;
    },
  },

  methods: {
    choosePage(page) {
      this.$emit("update:modelValue", page);
    },
  },
};
</script>
