<template>
  <div
    class="card shadow-sm"
    data-bs-toggle="modal"
    :data-bs-target="'#modalPaquete-' + paquete.paquetecodigo"
  >
    <div class="card-body">
      <div class="row">
        <div class="col-12 mb-3">
          <paquete-title :paquete="paquete"></paquete-title>
        </div>
        <div
          class="
            col-4
            border-end
            d-flex
            align-items-center
            justify-content-center
          "
        >
          <span class="badge bg-success" v-if="paquete.estado == 'C'">
            {{ $t("paquete.estados.retirado") }}
          </span>
          <span class="badge bg-warning text-dark" v-else>
            {{ $t("paquete.estados.transito") }}
          </span>
        </div>
        <div class="col-4 border-end text-center">
          <h5 class="mb-0">{{ paquete.embarquecodigo }}</h5>
          <small>{{ $t("paquete.embarque") }}</small>
        </div>
        <div class="col-4 text-center">
          <h5 class="mb-0">{{ paquete.vaatere }}</h5>
          <small>{{ $t("paquete.factura") }}</small>
        </div>
      </div>
    </div>
  </div>
  <paquete-modal :paquete="paquete" />
</template>

<style scoped>
.card {
  border-radius: 1rem;
  cursor: pointer;
}
.badge {
  border-radius: 1rem;
  padding: 0.5rem 0.75rem;
}
.pack {
  background-color: var(--primary-color--500);
  border-radius: 1rem;
  fill: white;
  padding: 0.5rem;
}

.bg-success {
  background-color: var(--green-color--100) !important;
  color: var(--green-color--500);
}
.bg-warning {
  background-color: var(--yellow-color--100) !important;
  color: var(--yellow-color--500);
}
</style>

<script>
import PaqueteModal from "@/components/PaqueteModal.vue";
import PaqueteTitle from "@/components/PaqueteTitle.vue";

export default {
  name: "PaqueteCard",

  components: {
    PaqueteModal,
    PaqueteTitle,
  },

  props: {
    paquete: {},
  },
};
</script>