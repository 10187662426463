<template>
  <div class="container">
    <h1 v-if="$route.name === 'paquetesBuscados'">
      {{ $t("paquetesBuscados") }}
    </h1>
    <div class="row">
      <div
        class="col-4 py-3"
        v-for="paquete in paquetes"
        :key="paquete.paquetecodigo"
      >
        <paquete-card :paquete="paquete" />
      </div>
      <div class="col-12">
        <div class="d-flex justify-content-center py-3">
          <paginator v-model="current_page" :last_page="last_page"></paginator>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Paginator from "@/components/Paginator.vue";
import PaqueteCard from "@/components/PaqueteCard.vue";

export default {
  name: "home",
  data() {
    return {
      paquetes: [],
      current_page: null,
      last_page: null,
    };
  },
  components: {
    Paginator,
    PaqueteCard,
  },
  watch: {
    $route: {
      immediate: true,
      handler(/* to, from */) {
        this.current_page = 1;
        this.listarPaquetes();
      },
    },
    current_page: {
      handler(/* to, from */) {
        this.listarPaquetes();
        window.scrollTo(0, 0);
      },
    },
  },

  methods: {
    listarPaquetes() {
      let endpoints = {
        dashboard: "paquetesPendientes",
        paquetesRetirados: "paquetesRetirados",
        paquetesBuscados: "paquetes",
      };

      let filtro = this.$route.query.filtro;
      let url = `/${endpoints[this.$route.name]}?page=${this.current_page}`;
      if (this.$route.name === "paquetesBuscados" && filtro) {
        url += `&q=${filtro}`;
      }

      axios
        .get(url)
        .then((response) => {
          const data = response.data ? response.data : {};
          this.paquetes = data.data;
          this.last_page = data.last_page;
        })
        .catch((response) => {
          console.log(response);
        });
    },
  },
};
</script>