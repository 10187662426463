<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.6884 4.67829L14.3169 2.73779C13.56 2.40741 12.7429 2.23682 11.917 2.23682C11.091 2.23682 10.2739 2.40741 9.51692 2.73779L5.14551 4.67829C4.29844 5.02136 3.57073 5.6056 3.05266 6.35847C2.5346 7.11134 2.24896 7.99974 2.2312 8.91347V14.922C2.24907 15.8357 2.53477 16.7241 3.05282 17.4769C3.57087 18.2298 4.29851 18.814 5.14551 19.1571L9.51692 21.0977C10.2665 21.4266 11.0761 21.5968 11.8947 21.5974C11.9024 21.5974 11.9092 21.6026 11.9178 21.6026C11.9256 21.6013 11.9333 21.5996 11.9409 21.5974C12.7595 21.5968 13.5691 21.4267 14.3187 21.0977L18.6901 19.1571C19.5371 18.8141 20.2649 18.2299 20.7829 17.477C21.301 16.7242 21.5866 15.8358 21.6044 14.922V8.91347C21.5866 7.99952 21.3007 7.11096 20.7823 6.35805C20.2639 5.60514 19.5358 5.021 18.6884 4.67829ZM10.1221 4.10062C10.6889 3.85376 11.3004 3.72635 11.9186 3.72635C12.5368 3.72635 13.1484 3.85376 13.7152 4.10062L18.0867 6.04112C18.3703 6.16715 18.6375 6.32749 18.8821 6.51855L16.3887 7.62692L9.28636 4.47175L10.1221 4.10062ZM5.75065 6.04028L7.4461 5.28777L14.5484 8.44284L13.1092 9.08234C12.7307 9.23995 12.3248 9.32111 11.9148 9.32111C11.5048 9.32111 11.0989 9.23995 10.7204 9.08234L4.95351 6.51855C5.19967 6.32793 5.46789 6.16762 5.75237 6.04112L5.75065 6.04028ZM5.75065 17.7951C5.16873 17.5668 4.66646 17.1731 4.30589 16.6625C3.94532 16.1519 3.74225 15.5468 3.72178 14.922V8.91347C3.72387 8.50094 3.81574 8.09383 3.99095 7.72035L10.1161 10.4426C10.4538 10.5857 10.8084 10.6854 11.1712 10.7392V20.034C10.8097 19.9792 10.4566 19.8786 10.1204 19.7349L5.75065 17.7951ZM20.1095 14.9229C20.0889 15.5476 19.8858 16.1526 19.5253 16.6632C19.1647 17.1738 18.6625 17.5677 18.0807 17.7961L13.7092 19.7366C13.373 19.8802 13.0199 19.9804 12.6584 20.0349V10.74C13.0212 10.6864 13.3757 10.5871 13.7135 10.4443L19.8387 7.72203C20.0135 8.09565 20.1053 8.50266 20.1078 8.91514L20.1095 14.9229Z"
      :fill="fillColor"
    />
  </svg>
</template>

<script>
export default {
  props: ["fill"],

  data() {
    return {
      fillColor: this.fill ? this.fill : "#343C44",
    };
  },
};
</script>