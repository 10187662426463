<template>
  <div
    :id="'modalPaquete-' + paquete.paquetecodigo"
    class="modal"
    tabindex="-1"
  >
    <div class="modal-dialog modal-fullscreen">
      <div class="modal-content">
        <div class="modal-body px-0">
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
          <div class="row">
            <div class="col-12 mb-5 text-center p-5 pb-0">
              <paquete-title :paquete="paquete"></paquete-title>
            </div>

            <div
              class="
                col-12
                border-end
                d-flex
                align-items-center
                justify-content-center
              "
            >
              <div v-if="paquete.estado == 'A-'">
                <timeline-0 width="100%"></timeline-0>
              </div>

              <div v-if="paquete.estado == 'A'">
                <timeline-25 width="100%"></timeline-25>
              </div>

              <div v-if="paquete.estado == 'B'">
                <timeline-50 width="100%"></timeline-50>
              </div>

              <div v-if="paquete.estado == 'B+'">
                <timeline-75 width="100%"></timeline-75>
              </div>

              <div v-if="paquete.estado == 'C'">
                <timeline-100 width="100%"></timeline-100>
              </div>

              <div v-if="paquete.estado == 'D'">
                <timeline-100 width="100%"></timeline-100>
              </div>
            </div>

            <div class="col-12 text-center main-content">
              <p class="mb-0">{{ $t("paquete.estado") }}</p>
              <div class="text-uppercase">
                <h2 v-if="paquete.estado == 'C'">
                  {{ $t("paquete.estados.retirado") }}
                </h2>
                <h2 v-else>
                  {{ $t("paquete.estados.transito") }}
                </h2>
              </div>
            </div>

            <div class="col-6 border-end text-center">
              <h5 class="mb-0">{{ paquete.embarquecodigo }}</h5>
              <small>{{ $t("paquete.embarque") }}</small>
            </div>
            <div class="col-6 text-center">
              <h5 class="mb-0">{{ paquete.vaatere }}</h5>
              <small>{{ $t("paquete.factura") }}</small>
            </div>
            <div class="col-12">
              <table class="w-100 my-5">
                <tr v-if="paquete.embarquefecha">
                  <td class="pe-3" align="right">Frontliner USA</td>
                  <td>{{ paquete.embarquefecha }}</td>
                </tr>
                <tr v-if="paquete.fechallegada">
                  <td class="pe-3" align="right">Frontliner Asunción</td>
                  <td>{{ paquete.fechallegada }}</td>
                </tr>
                <tr v-if="paquete.fecharetiro">
                  <td class="pe-3" align="right">Retirado el</td>
                  <td>{{ paquete.fecharetiro }}</td>
                </tr>
              </table>
            </div>
            <div class="col-12">
              <table class="subtotal w-100">
                <tr>
                  <td class="px-3 py-2">
                    <tarifa-icon fill="#fff"></tarifa-icon>
                    {{ $t("paquete.tarifa") }}
                  </td>
                  <td align="right">{{ paquete.tarifapreciocli }}</td>
                </tr>
                <tr>
                  <td class="px-3 py-2">
                    <tarifa-icon fill="#fff"></tarifa-icon>
                    {{ $t("paquete.peso") }}
                  </td>
                  <td align="right">{{ paquete.paquetepeso }}</td>
                </tr>
                <tr>
                  <td class="px-3 py-2">
                    <total-icon fill="#fff"></total-icon>
                    {{ $t("paquete.total") }}
                  </td>
                  <td align="right">
                    <h3 class="text-white">
                      Gs.
                      {{ paquete.tarifapreciocalc * paquete.tasa }}
                    </h3>
                  </td>
                </tr>
                <tr v-if="paquete.estado != 'C'">
                  <td colspan="2" class="p-3 text-center">
                    <button class="btn btn-primary">Agregar factura</button>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.modal-fullscreen {
  width: 25rem;
  margin-left: calc(100vw - 25rem);
}

.btn-close {
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.subtotal {
  background-color: var(--primary-color--500);
  color: white;
}

.main-content {
  margin-top: -6rem;
  margin-bottom: 2rem;
}

.btn-primary{
  background-color: var(--primary-color--600);
  border-color: var(--primary-color--600);
}
</style>

<script>
import PaqueteTitle from "@/components/PaqueteTitle.vue";
import TarifaIcon from "@/components/icons/TarifaIcon.vue";
import TotalIcon from "@/components/icons/TotalIcon.vue";
import Timeline0 from "@/components/timeline/Timeline-0.vue";
import Timeline25 from "@/components/timeline/Timeline-25.vue";
import Timeline50 from "@/components/timeline/Timeline-50.vue";
import Timeline75 from "@/components/timeline/Timeline-75.vue";
import Timeline100 from "@/components/timeline/Timeline-100.vue";

export default {
  name: "PaqueteModal",
  components: {
    PaqueteTitle,
    TarifaIcon,
    TotalIcon,
    Timeline0,
    Timeline25,
    Timeline50,
    Timeline75,
    Timeline100,
  },
  props: {
    paquete: {},
  },
};
</script>