<template>
  <div>
    <pack-icon
      class="float-start mt-1 me-3 pack"
      width="48"
      height="48"
      fill="#fff"
    ></pack-icon>
    <h3 class="text-nowrap mb-0">{{ paquete.paquetedescripcion }}</h3>
    <p class="mb-0 text-nowrap">
      {{ $t("paquete.tracking") }}
      {{ paquete.paquetetracking }}
    </p>
  </div>
</template>

<style scoped>
.text-nowrap {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.pack {
  background-color: var(--primary-color--500);
  border-radius: 1rem;
  fill: white;
  padding: 0.5rem;
}
</style>

<script>
import PackIcon from "@/components/icons/PackIcon.vue";

export default {
  name: "PaqueteTitle",

  components: {
    PackIcon,
  },

  props: {
    paquete: {},
  },
};
</script>